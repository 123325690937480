<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import api from '@/services/secureHttps';
import { mapGetters } from 'vuex';

export default {
    components: {
        Layout,
        PageHeader
    },

    data() {
        return {
            title: "Add Page",
            url: null,
            items: [],
            validationErroMsg: null,
            successMsg: null
        };
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId', 'selectedWebsiteUrl'
        ])
    },

    methods: {
        submitUrl() {
            // var fullUrl = this.selectedWebsiteUrl + this.url;
            var enteredUrls = this.url.split(/\r?\n/).filter(element => element);
            enteredUrls.forEach((item, index) => {
                enteredUrls[index] = this.selectedWebsiteUrl + item;
            });

            console.log(enteredUrls);

            var data = {
                urls: enteredUrls
            }

            api.addWebPageUrls(this.selectedWebisteId, data)
                .then(response => {
                    //todo: handle validation and success messages
                    console.log(response);

                    if (response.status >= 200 && response.status < 300) {
                        this.successMsg = "Successfully added your URL(s)";
                        this.validationErroMsg = null;
                    }
                    else if (response.status >= 400 && response.status < 500) {
                        this.validationErroMsg = response.data.detail;
                        this.successMsg = null;
                    } else {
                        this.validationErroMsg = "Sorry, an unknown error occured. Please try again.";
                        this.successMsg = null;
                    }
                });
        }
    }
}
</script>
        
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col">
                <div class="h-100">
                    <div class="row mb-3 pb-1">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <p class="
                            text-uppercase
                            fw-bold
                            text-truncate
                            mb-0
                            ">
                                    Import Website Sitemap
                                </p>

                            </div>
                            <!-- end card header -->

                            <!-- card body -->
                            <div class="card-body">
                                <p>If you can't find your web page in the list of web pages. You can add it here</p>
                                <p>You can add one or multiple URL's at once. For multiple URL's use a single line per page.
                                </p>
                                <form id="medaDataForm" class=".needs-validation" @submit.prevent="submitUrl">
                                    <label class="form-label mb-0">Enter URL</label>
                                    <div class="input-group">

                                        <span class="input-group-text" id="urlPrefix">{{ selectedWebsiteUrl }}</span>
                                        <textarea class="form-control" aria-describedby="urlPrefix" v-model="url"
                                            placeholder="e.g. blog/post_1.html" rows="5" />
                                    </div>
                                    <div v-if="validationErroMsg !== null" class="text-danger">
                                        <small class="text-danger">{{ validationErroMsg }}</small>
                                    </div>
                                    <div v-if="successMsg !== null" class="text-success">
                                        <small class="text-success">{{ successMsg }}</small>
                                    </div>

                                    <div class="pt-3">
                                        <div class="flex align-items-end justify-content-end">
                                            <button variant="primary"
                                                class="btn btn-primary btn-animation waves-effect waves-light float-right"
                                                data-text="Add page"><span>Submit</span></button>
                                        </div>
                                    </div>
                                </form>
                                <!-- end card body -->
                            </div>

                            <!-- end card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>